p {
  font-size: 1rem;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  flex: 1 0 auto;
}


.header{
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
  background-color: white;
}

.footer{
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
}

.footer img{
  width: 2rem;
}

.footer div {
  padding: 1rem 2rem;
}

.links{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

.headerLogo{
  width: 8rem;
}

h1{
  font-weight: bold;
  font-size: 8rem;
  margin: 0rem;
}

a{
  font-weight: bold;
  text-decoration: none;
  color: black;
}
a:hover{
  text-decoration: underline;
}

.links >h2{
  font-size: 2rem;
}

.imprint{
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
    }


  .headerLogo{
    width: 5rem;
  }
  .header a{
    font-size: 2rem;
  }

  .header {
    padding: 1rem;
    gap: 1rem;
  }


}